
.ContainerStyle {
    align-items: vertical;
    display: inline-grid;
    border-radius: 25px;
  }

.ButtonsStyle {
    margin-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
  }

.DateStyle {
    margin-left: 1.3rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: inline-flex;
    border-radius: 25px;
  }

  
.transform {
    -webkit-transition: all 2s ease;  
    -moz-transition: all 2s ease;  
    -o-transition: all 2s ease;  
    -ms-transition: all 2s ease;  
    transition: all 2s ease;
  }
  
  .transform-active {
    background-color: #45CEE0;
    height: 200px;
    width: 200px;
  }

  @media only screen and (max-width: 700px) {
    .ButtonsStyle {
      margin-left: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
  
  .DateStyle {
      margin-left: 1.3rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      border-radius: 25px;
    }
  }
